<template>
  <div class="test_group_warp">
    <div class="left">
      <LeftController @initList="initList"
                      ref="subjectRef" />
    </div>
    <div class="right">
      <TopCard ref="TopCardRef"
               text="我的试卷"
               :isTabs="true"
               :tabList="tabList"
               :showBack="false" />
      <div class="list">
        <!-- 我的试卷 未批改试卷 已批改试卷 列表 -->
        <div class="warp"
             v-if="idx < 3">
          <el-row v-for="(row,index) in tableData"
                  :key="index">
            <el-col :span="10">
              <div class="paper_name">
                <div class="img">
                  <img src="@/assets/choiceness/mypaper.png"
                       alt="">
                </div>
                <div class="text">
                  <div>
                    {{row.template_name}}
                  </div>
                  <div>
                    <span>
                      试卷ID:</span>
                    <span>
                      {{row.user_paper_id}}
                    </span>
                  </div>
                  <div class="comment">
                    <span>
                      试卷总评:</span>
                    <span>
                      {{row.paper_comment?row.paper_comment:'无'}}
                    </span>
                  </div>
                  <div class="comment">
                    <span>
                      试卷描述:</span>
                    <span>
                      {{row.paper_desc?row.paper_desc:'无'}}
                    </span>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="score"
                   style="color:#666666;">
                <!-- 总分：{{row.paper_score}} -->
                {{row.paper_time}}
              </div>
            </el-col>
            <el-col :span="3">
              <div class="score">
                <span style="font-weight:bold;">得分：</span><span style="color:#FF634C">{{row.user_score}}</span>
              </div>
              <div class="score"
                   style="color:#666666;margin-top:5px">
                总分：{{row.paper_score}}
              </div>
            </el-col>
            <el-col :span="4"
                    class="desc_col">
              <div :class="{'desc':true,'check':row.correct_type == 2 && selfCorrectStatus.indexOf(row.judge_status) != -1}">
                {{getValue(row)}}
              </div>
            </el-col>
            <el-col :span="3">
              <div class="btn_warp">
                <div class="btn"
                     style="background: #fe8374;"
                     @click="toCorrect(row)"
                     v-if="row.correct_type == 2 && selfCorrectStatus.indexOf(row.judge_status) != -1">
                  <!-- 自评状态  且 未批改 -->
                  批改试卷
                </div>
                <div class="btn"
                     @click="toAnalysis(row)"
                     v-else>
                  答案解析
                </div>
              </div>
            </el-col>
          </el-row>
        </div>

        <!-- 未完成试卷列表 -->
        <div class="warp"
             v-else>
          <el-row v-for="(row,index) in tableData"
                  :key="index">
            <el-col :span="18">
              <div class="paper_name">
                <div class="img">
                  <img src="@/assets/choiceness/underpaper.png"
                       alt="">
                </div>
                <div class="text">
                  <div>
                    {{row.template_name}}
                  </div>
                  <div>
                    <span>
                      试卷ID:</span>
                    <span>
                      {{row.user_paper_id}}
                    </span>
                  </div>
                  <div>
                    做卷时间：{{row.paper_time}}
                  </div>
                  <div>
                    试卷描述：{{row.paper_desc}}
                  </div>
                </div>
              </div>
            </el-col>

            <el-col :span="3">
              <div class="score">
                <span style="font-weight:bold;">用时：</span><span style="color:#FF634C">0分</span>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="btn_warp">
                <div class="btn"
                     @click="doPaper(row)">
                  继续做卷
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftController from '@/views/components/left_controller.vue'
import TopCard from '@/views/components/top_card/index.vue'
import { getMyPaper } from '@/api/my_paper.js'
export default {
  data () {
    return {
      tableData: [],
      paperType: 1,
      id: 0,
      idx: 0,
      tabList: [
        { label: '我的试卷', value: -1 },
        { label: '未批改试卷', value: 0 },
        { label: '已批改试卷', value: 1 },
        { label: '未完成试卷', value: 2 },
      ],
      limit: 20,
      page: 1,
      selfCorrectStatus: [101, 1, 102, 2, 103, 3, 10, 7, 107]
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name == 'index') {
      from.meta.keep = false;
    }
    next();
  },
  components: {
    LeftController, TopCard
  },
  mounted () {
    this.$removePaperInfo()
    document.getElementsByClassName('list')[0].addEventListener('scroll', this.addScrollEvent)
  },
  activated () {
    if (!this.$refs.subjectRef.subjectList.length) {
      this.$refs.subjectRef.initData()
    }
  },
  methods: {
    addScrollEvent () {
      var olist = document.getElementsByClassName('list')[0]
      // 容器高度 + 滚动条距离顶边的距离 = 滚动条的高度
      if (olist.clientHeight + olist.scrollTop == olist.scrollHeight) {
        this.page += 1
        this.initList(this.id)
      }
    },
    checkStatus (index) {
      this.idx = index
      this.page = 1
      this.initList(this.id)
      this.tableData = []
    },
    async initList (id) {
      if (id != this.id) {
        this.id = id
        this.page = 1;
        this.tableData = []
      }
      let params = {
        subject_id: id,
        status: this.tabList[this.idx].value,
        page: this.page,
        limit: 20
      }
      const { data } = await getMyPaper(params)
      this.tableData = [...this.tableData, ...data.volist]
    },
    toCorrect (row) {
      this.$router.push('/self_correct?paper_id=' + row.user_paper_id)
    },
    toAnalysis (item) {
      this.$router.push('/analysis?paper_id=' + item.user_paper_id)
    },
    doPaper (row) {
      this.$router.push('/doPaper?type=2&form=' + JSON.stringify({ user_paper_id: row.user_paper_id }))
    },
    getValue (row) {
      var valus = {
        101: '选择题系统自动批改，非选择题未批改',
        1: '选择题系统自动批改，非选择题未批改',
        102: '系统自动批改，大题学生部分批改',
        103: '学生部分批改（全大题，批改部分）',
        104: '学生完成批改（全大题，全部批改完成）',
        4: '全部批改完成（选择题+ 大题）',
        105: '系统自动批改完成（全选择题）',
        106: '全部批改完成（选择题+ 大题）',
        107: '大题全部未批改',
        16: '试卷批改中'

      }
      return valus[row.judge_status]
    }
  }
}
</script>

<style lang="scss" scoped>
.test_group_warp {
  overflow: hidden;
  display: flex;
  .left {
    width: 100rem;
  }
  .right {
    width: calc(100% - 100rem);
    background: #f6f6f6;
    .tabs {
      margin-top: 22rem;
      display: flex;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 18rem;
      .tab {
        cursor: pointer;
        width: 260rem;
        height: 54rem;
        background: #2196f3;
        border-radius: 30rem;
        color: #ffffff;
        font-size: 24rem;
        font-weight: 800;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .list {
      width: 100%;
      max-height: calc(100vh - 60rem - 40rem);
      -ms-overflow-style: none;
      background: white;
      overflow-y: auto;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      .el-row {
        padding: 10rem;
        border-bottom: 1rem solid #f1f1f1;
        display: flex;
        align-items: center;
        &:hover {
          background: rgba($color: #2196f3, $alpha: 0.1);
        }
      }
      // div {
      //   height: 80rem;
      //   line-height: 80rem;
      // }
      .paper_name {
        margin-left: 50rem;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        // height: 110rem;
        padding: 10rem;
        .img {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100rem;
          height: 70rem;
          background: #6cb9f5;
          border-radius: 5rem;
          flex-shrink: 0;
          img {
            width: 34rem;
            height: 34rem;
          }
        }
        .text {
          min-height: 70rem;
          margin-left: 20rem;
          div {
            width: 700rem;
            &:nth-child(1) {
              font-size: 20rem;
              font-weight: bold;
              color: #000000;
            }
            &:nth-child(2) {
              margin-top: 10rem;
              font-size: 20rem;
              font-weight: bold;
              color: #000000;
              // line-height: 20rem;
            }
            &:nth-child(3) {
              margin-top: 10rem;
              font-weight: 500;
              font-size: 20rem;
              color: #333333;
              display: flex;
              flex-wrap: wrap;
              word-wrap: break-word;
              span {
                &:nth-child(1) {
                  width: 100rem;
                }
                &:nth-child(2) {
                  width: calc(100% - 200rem);
                }
              }
            }
            &:nth-child(4) {
              margin-top: 10rem;
              font-weight: 500;
              font-size: 20rem;
              color: #333333;
              display: flex;
              flex-wrap: wrap;
              word-wrap: break-word;
              span {
                &:nth-child(1) {
                  width: 100rem;
                }
                &:nth-child(2) {
                  width: calc(100% - 200rem);
                }
              }
            }
          }
        }
      }
      .score {
        font-weight: 500;
        color: #333333;
        font-size: 20rem;
        // height: 110rem;
        display: flex;
        align-items: center;
        margin-left: 30rem;
      }
      .btn_warp {
        display: flex;
        // height: 110rem;
        align-items: center;
        justify-content: flex-end;
        .btn {
          margin-right: 20rem;
          width: 120rem;
          height: 44rem;
          background: #2196f3;
          border-radius: 5rem;
          font-size: 20rem;
          font-weight: bold;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
}
.desc_col {
  display: flex;
  align-items: center;
  // height: 110rem;
  justify-content: flex-end;
  .desc {
    padding: 5rem 30rem;
    background: #ececec;
    border-radius: 16rem;
    font-weight: 500;
    font-size: 16rem;
  }
}
.check {
  background: #ffecea !important;

  color: #ff6552 !important;
}
</style>